import {ReactNode} from "react";
import Settings from "../lib/settings";

type ICenteredFooterProps = {
    logo: ReactNode;
};

const FooterCopyright = (props: ICenteredFooterProps) => {
    const link = window.location !== window.parent.location ? '' : Settings.getLandingURL();

    return (<div className="footer-copyright">
            <a className="text-primary-500 sm:text-primary-100" href={link}> Propelled by <span
                className="absolute pl-2">{props.logo}</span></a>
        <style>
            {`
        .footer-copyright :global(a) {
          @apply text-primary-100;
        }

        .footer-copyright :global(a:hover) {
          @apply underline;
        }
      `}
        </style>
    </div>)
};

export { FooterCopyright };

/* eslint-disable */
import Settings from './settings';

class ApiCaller {
    token: string;

    constructor(token?: string) {
        this.token = token ? token : "";
    }

    _generateRequestData(url: string, method: string, params: any, token?: string): { url: string, fetchParams: any} {
        const fetchParams: any = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        if (params) {
            fetchParams.body = JSON.stringify(params);
        }

        if (token) {
            fetchParams.headers['x-access-token'] = token;
        }

        let separator = '?';
        if(url.indexOf('?') !== -1) {
            separator = '&';
        }

        const defUrl = url + (this.token ? separator + "token=" + this.token : "");

        return { url: defUrl, fetchParams: fetchParams }
    }

    call(url:string, method:string, params?:object, token?:string) : Promise<any>{
        const requestData = this._generateRequestData(url, method, params, token);

        return fetch(Settings.getApiURL() + requestData.url, requestData.fetchParams)
            .then(response => response.json());
    }

    download(url: string, method: string, params?: object): Promise<any> {
        const requestData = this._generateRequestData(url, method, params)

        return fetch(Settings.getApiURL() + requestData.url, requestData.fetchParams).then(
            (response) => {
                if (response.status === 404) {
                    throw new Error('Document not found in the system.');
                }
                return response.blob();
            },
        );
    }
}

export default ApiCaller;

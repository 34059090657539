import { Section } from '../layout/Section';
import {DropArea} from "../droparea/dropArea";
import {Button} from "../button/Button";

type IUploadProps = {
    _onResponse: Function;
    _onReset: Function;
};

const PdfUpload = (props:IUploadProps) => (
  <Section yPadding={'px-10'}>
    <DropArea _onResponse={props._onResponse}
              _onReset={props._onReset}
              title="Drag and drop the PDF here."
              subtitle="or"
              button={<Button hideMobile={false}>Upload from your computer</Button>}
    />
  </Section>
);

export { PdfUpload };

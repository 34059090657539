/* eslint-disable */

const Settings = {
    getApiURL: (): string => {
        //const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost';
        const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https:/navocerts.com';
        return `${baseUrl}/api`;
    },

    getLandingURL: (): string => {
        return 'https://n-cap.net';
    }
}

export default Settings;

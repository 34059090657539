
import {formatDateTimeUTC} from "../utils/DateUtil";
import { useMediaQuery } from 'react-responsive'
import {StepperStatus} from "./StepperStatus";

type IDropdownProps = {
    src: any;
    image: any;
    hideSteps?: boolean;
};

const VerifierMain = (props: IDropdownProps) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    return ( <>

            { isDesktopOrLaptop &&

            <div className="flex flex-row flex-wrap p-10 pt-0 pb-0 sm:space-x-5 md:space-x-5 lg:space-x-5 xl:space-x-5 2xl:space-x-5">

                <div className="flex-initial w-full sm:w-1/4 md:w-1/4 lg:w-64 xl:w-64 2xl:w-64 p-4 bg-primary-100 rounded-2xl border-1 border-black" >
                    <div className="items-center text-center " >
                        <img alt="" className="p-2" src={props.src.badge?.issuer?.image ? props.src.badge?.issuer?.image : props.image } />
                    </div>
                </div>

                <div className="flex-1 w-full sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 p-4 bg-primary-100 text-primary-900 rounded-md border-1 border-black shadow">
                    <div className="flex flex-col space-y-2 p-5 text-lg " >
                        <div>Issuer: {props.src.badge?.issuer?.name}</div>
                        <div>Recipient: {props.src.recipientProfile?.name}</div>
                        <div className="border-b"/>
                        <div>Issued on: {formatDateTimeUTC(props.src.issuedOn)}</div>
                        { props.src?.expires && (
                            <div>Valid Until: {formatDateTimeUTC(props.src.expires)}</div>
                        )}
                    </div>
                </div>
            </div>

            }

            {!isDesktopOrLaptop &&

            <div className="flex flex-row flex-wrap px-20">
                <div className="flex-initial w-full p-4 bg-primary-100 rounded-2xl border-1 border-black  ">
                    <div className="items-center text-center ">
                        <img alt="" className="p-2"
                             src={props.src.badge?.issuer?.image ? props.src.badge?.issuer?.image : props.image}/>
                    </div>
                </div>
            </div>

            }

            { !props.hideSteps && (
                <div className="sm:pl-10 sm:pr-10 pr-2 pl-2 sm:pt-10 sm:pb-10 pt-4 pb-4">
                    <StepperStatus src={props.src} />
                </div>
            )}

            {!isDesktopOrLaptop &&
            <div className="flex flex-row flex-wrap p-2 pt-0">
                <div className="flex-1 w-full sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 px-2 py-3 bg-primary-100 text-primary-900 rounded-md border-1 border-black shadow">
                    <div className="flex flex-col space-y-1 p-2 text-base" >
                        <div>Issuer: {props.src.badge?.issuer?.name}</div>
                        <div>Recipient: {props.src.recipientProfile?.name}</div>
                        <div className="border-b"/>
                        <div>Issued on: {formatDateTimeUTC(props.src.issuedOn)}</div>
                        { props.src?.expires && (
                            <div>Valid Until: {formatDateTimeUTC(props.src.expires)}</div>
                        )}
                    </div>
                </div>
            </div>

            }
        </>
    );

}

export { VerifierMain };

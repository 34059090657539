import {ReactNode, useCallback, useState} from 'react';
import Dropzone from 'react-dropzone'
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import {retrieve} from "../utils/retrieve";
import deleteIcon from '../images/delete_button.svg'

type IDropAreaProps = {
    title: string;
    subtitle: string;
    button: ReactNode;
    _onResponse: Function;
    _onReset: Function;
};

const DropArea = (props: IDropAreaProps) => {

    const [uploading, setUploading] = useState(false);
    const [filename, setFilename] = useState("File Example.pdf");
    const [actionMessage, setActionMessage] = useState(<><div className="text-primary-900 text-lg font-semibold p-4 ">Uploading...</div>
    <div className="pt-3 ">
        <LoadingSpinner />
    </div></>);

    const uploadToServer = async (dropFile:any) => {
        setUploading(true);
        setFilename(dropFile.name);
        return await retrieve(dropFile);
    };

    const resetView = () => {
        setUploading(false);
        setFilename('');
        setActionMessage(<></>);
        props._onReset();
    }

    const onDrop = useCallback( (acceptedFiles) => {
        acceptedFiles.forEach(async (file:any) => {
            const response:any = await uploadToServer(file);
            let r = await response;
            if (r.status === 'ok') {
                setActionMessage(<div className="text-primary-900 text-lg font-semibold  p-4 ">{r.message}</div>);
                props._onResponse(r);
            } else {
                setActionMessage(<div className="text-red-700 text-lg font-semibold  p-4 ">{r.message}</div>);
            }
        })
    }, [])

    if (uploading)
        //File name and Uploading status
        return (<>
                <section>
                    <div className="flex flex-row pb-10 pt-0">
                        <div className="flex w-full bg-primary-100 rounded-md border-2 border-gray-700">
                            <div className="flex flex-1">
                                <span className="text-primary-900 text-md p-4">{filename}</span>
                            </div>
                            <div className="flex flex-wrap pr-10 cursor-pointer">
                                <img alt="" src={deleteIcon} onClick={resetView}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="flex flex-row pb-10 pt-0 ">
                        <div className="flex-1 bg-primary-100 rounded-md ">
                            <div className="w-full items-center flex flex-col">
                                <div className="flex flex-row">
                                    {actionMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
     else
            return (
            <Dropzone onDrop={onDrop} >
                {({getRootProps, getInputProps}) => (
                    <section className="bg-primary-100 p-20 rounded-md border-2 border-black border-dashed ">
                        <div {...getRootProps()} className="w-full items-center flex flex-col items-center text-center" >
                            <input {...getInputProps()} />
                            <img src="/assets/images/upload_icon.png" alt="Upload" />

                            <div className="pb-1">

                                <div className="text-4xl text-gray-900 font-bold">{props.title}</div>
                                <div className="text-gray-900">{props.subtitle}</div>
                            </div>

                            <div className="rounded-md whitespace-no-wrap mt-3 sm:mt-0 sm:ml-2 text-grey-700 shadow-sm shadow-primary-600">
                                {props.button}
                            </div>

                        </div>
                    </section>
                )}
            </Dropzone>
             )
}

export { DropArea };

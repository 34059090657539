
const LogoDark = () => {
  return (
    <span className={`text-gray-900 inline-flex items-center`}>
        <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0871 0.837218C10.1098 0.773623 10.064 0.704102 9.99648 0.704102H8.64044C8.53877 0.704102 8.4463 0.759957 8.40203 0.851483C7.50893 2.69816 4.69584 9.58614 0.219077 20.8906C0.206591 20.9222 0.199951 20.9564 0.199951 20.9903V22.7285C0.199951 22.8771 0.32039 22.9975 0.46896 22.9975H0.95904C1.06869 22.9975 1.16736 22.931 1.20845 22.8293L2.89272 18.6618C5.49798 12.2162 9.85876 1.42779 10.0871 0.837218Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.199951 16.2622C1.0092 14.2602 1.89192 12.0748 2.73177 9.99464C2.93868 9.4824 3.14251 8.97775 3.34174 8.4846C4.97934 4.43057 6.30375 1.15293 6.4424 0.827501C6.46694 0.769966 6.42547 0.704102 6.36292 0.704102H5.00542C4.90399 0.704102 4.81176 0.759548 4.76743 0.850775C4.15782 2.10517 2.63738 5.74484 0.2394 11.691C0.213614 11.7549 0.199951 11.8242 0.199951 11.8932V16.2622Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9443 0.860966C13.9798 0.789072 13.9276 0.704102 13.8474 0.704102H12.524C12.4224 0.704102 12.3341 0.751565 12.2898 0.84289C10.8878 3.73166 4.84738 18.9922 3.40935 22.6346C3.33967 22.8111 3.47165 22.9976 3.66142 22.9976H4.79902C4.90858 22.9976 5.00687 22.9319 5.048 22.8304C5.62831 21.3977 6.78084 18.5504 8.06902 15.3671C10.5958 9.1239 13.7399 1.35397 13.9443 0.860966Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3267 12.3008C18.3874 12.5578 18.7434 12.5825 18.8392 12.3365L19.6275 10.3103C19.6476 10.2588 19.6512 10.2023 19.638 10.1486L17.3484 0.831229C17.3467 0.824489 17.3465 0.826955 17.3458 0.82005C17.3394 0.756664 17.2797 0.704102 17.216 0.704102H16.1509C16.0529 0.704102 15.961 0.756867 15.9227 0.8471C15.8205 1.08738 15.7849 1.32502 15.773 1.44326C15.7702 1.47074 15.7725 1.49833 15.7789 1.52521L18.3267 12.3008Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.3257 16.0278L14.6733 5.26928C14.6103 5.01378 14.2558 4.99136 14.1611 5.2369L13.451 7.07872C13.4308 7.13121 13.427 7.18662 13.4411 7.24107C14.0996 9.77334 15.4633 15.0062 16.1644 17.6622C16.2306 17.9129 16.577 17.932 16.6721 17.6908C16.8879 17.1435 17.1256 16.5384 17.3257 16.0278Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7032 22.2052L27.494 0.704102H25.5476L17.6874 20.5308C17.5876 20.9322 17.1356 21.6362 16.0868 21.6107C15.0009 21.5809 14.6502 20.7295 14.6116 20.2572L12.2182 10.2439L11.0508 13.0441L13.6297 22.482C13.6539 22.531 13.7846 22.7653 14.1509 22.9972H18.0001C18.4862 22.6511 18.6885 22.2368 18.7032 22.2052Z" fill="url(#paint0_diamond_316_668)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.4759 22.479L22.4801 22.4687L31.3006 1.07565C31.3736 0.89858 31.2434 0.704102 31.0519 0.704102H29.6862C29.5765 0.704102 29.4777 0.77072 29.4367 0.872457L20.8568 22.1377C20.8538 22.1466 20.7892 22.3293 20.6688 22.5816C20.5792 22.7695 20.7109 22.9976 20.919 22.9976H21.9425C22.0004 22.9976 22.0573 22.9793 22.102 22.9424C22.3847 22.7092 22.4718 22.4897 22.4759 22.479Z" fill="#040714"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.5589 22.2389C26.9988 21.1783 29.3981 15.2109 31.5304 9.90813C31.5432 9.87623 31.5497 9.84232 31.5497 9.80793V5.44861C29.062 11.6083 25.1952 21.1818 24.8607 22.0447C24.7875 22.2324 24.7091 22.41 24.6265 22.576C24.5323 22.7651 24.6646 22.9999 24.8759 22.9999H25.9182C25.984 22.9999 26.048 22.9764 26.0949 22.9302C26.2852 22.7426 26.438 22.5147 26.5589 22.2389Z" fill="#040714"/>
<defs>
<radialGradient id="paint0_diamond_316_668" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.4424 21.0293) rotate(-74.6669) scale(25.0903 100.29)">
<stop stop-color="#2EC6E6"/>
<stop offset="0.985613" stop-color="#67E6DE" stop-opacity="0.8"/>
</radialGradient>
</defs>
</svg>

    </span>
  );
};

export { LogoDark };

import { ReactNode } from 'react';

type ICTABannerProps = {
  title: string;
  subtitle: string;
  button?: ReactNode;
};

const Header = (props: ICTABannerProps) => (

  <div className="text-left flex flex-row justify-between sm:flex-row sm:items-center pt-6 pb-3 rounded-md sm:text-primary-100 sm:mr-10 ml-4 mr-4 sm:ml-10 truncate">

    <div>
      <h2 className="text-lg sm:text-5xl">{props.title}</h2>
      <h3 className="text-base sm:text-4xl">{props.subtitle}</h3>
    </div>

    {props.button && <div className="whitespace-no-wrap mt-3 sm:mt-0 sm:shadow sm:shadow-primary-600 rounded-md">
      {props.button}
    </div>}

  </div>
);

export { Header };

/* eslint-disable */
import ApiCaller from '../lib/ApiCaller';

class DocumentsApi {

    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getCredentialPayload(id: string): Promise<void | any> {
        const url = '/public/credentials/' + id + '?format=json';
        console.log('Get Credential')
        return this.apiCaller.call(url, 'GET').then((res: any) => {
            return res
        })
    }

    getCredentialDocument(id: string): Promise<void | any> {
        const url = `/documents/download/${id}`;
        console.log('Get credential document');

        return this.apiCaller.download(url, 'GET').then((file) => {
            return file;
        });
    }

    getRevocationList(id: string): Promise<void | any> {
        const url = '/public/crl/credential/' + id;
        console.log('Get revocations list')
        return this.apiCaller.call(url, 'GET').then((res) => {
            return res
        })
    }
}

export default DocumentsApi;

import {ButtonStatus} from "../button/ButtonStatus";


type IStatusProps = {
    verificationStatus?: string;
};

const VerificationStatus = (props: IStatusProps) => (
    <section>
    { props.verificationStatus ?
        props.verificationStatus === 'success' ? <ButtonStatus bold xl type="success">Verified</ButtonStatus> :  <ButtonStatus bold xl type="invalid">Invalid</ButtonStatus>
            : <></>

    }
    </section>

);

export { VerificationStatus };

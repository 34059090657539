/* eslint-disable */
import ApiCaller from "../../lib/ApiCaller";
import DocumentsApi from "../../api/DocumentsApi";

class Navocerts {
    hash: string;
    src: any;

    constructor(src: any, hash?: string) {
        this.hash = hash ? hash : "";
        this.src = src;
    }

    call(): Promise<void | any> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.getCredentialPayload(this.hash).then((doc) => {
            if (doc.type[1] === "OPENBADGES") {
                this.src.hash = this.hash;
                this.src.badge.name = doc.credentialSubject.badge.name;
                this.src.badge.description = doc.credentialSubject.badge.description ? doc.credentialSubject.badge.description : "Port Clearance Documentation";
                this.src.issuedOn = doc.issuanceDate;
                this.src.expires = doc.expirationDate
                // this.src.recipient.identity = doc.captainId;
                this.src.recipientProfile.name = doc.credentialSubject.recipientProfile.name;
                // // if we modify schema, then validation is broken
                // //this.src.STATUS = doc.status;
                this.src.badge.issuer.email = doc.credentialSubject.badge.issuer.email;
                this.src.badge.issuer.image = doc.credentialSubject.badge.issuer.image;
                this.src.badge.issuer.name = doc.credentialSubject.badge.issuer.name;
                //this.src = doc.credentialSubject
            } else {
                this.src.badge.name = "Document";
                this.src.badge.description = "Scanned document file using navocerts.";
                this.src.issuedOn = doc.issuanceDate;
                this.src.recipientProfile.name = doc.credentialSubject.recipientName;
                this.src.badge.issuer.email = "info@navocerts.com"
                this.src.badge.issuer.image = ''
                this.src.badge.issuer.name = doc.issuer;
            }

            return this.src;
        }).catch(() => {

            this.src.badge.name = '';
            this.src.badge.description = '';
            this.src.badge.issuer.email = '';
            this.src.badge.issuer.image = '';
            this.src.badge.issuer.name = '';
            this.src.issuedOn = '';
            this.src.recipient.identity = '';
            this.src.recipientProfile.name = '';
            this.src.STATUS = 'UNKNOWN';
            this.src.badge.issuer.email = 'UNKNOWN';
            return this.src;
        })
    }

    verifyRevoked(hash: string): Promise<boolean> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.getRevocationList(hash).then((revocationList) => {
            const assertions = revocationList.revokedAssertions;
            return !!(assertions && assertions.length);
        }).catch((err) => {
            console.error(err.message);
            return true
        })
    }
}

export default Navocerts;


const Logo = () => {
  return (
    <span className={`text-gray-900 inline-flex items-center`}>
        <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0874 0.837218C10.1101 0.773623 10.0642 0.704102 9.99673 0.704102H8.64068C8.53901 0.704102 8.44654 0.759957 8.40228 0.851483C7.50918 2.69816 4.69608 9.58614 0.219321 20.8906C0.206835 20.9222 0.200195 20.9564 0.200195 20.9903V22.7285C0.200195 22.8771 0.320635 22.9975 0.469204 22.9975H0.959284C1.06893 22.9975 1.16761 22.931 1.20869 22.8293L2.89297 18.6618C5.49822 12.2162 9.85901 1.42779 10.0874 0.837218Z" fill="white"/>
            <path d="M0.200195 16.2622C1.00944 14.2602 1.89217 12.0748 2.73202 9.99464C2.93892 9.4824 3.14275 8.97775 3.34198 8.4846C4.97958 4.43057 6.30399 1.15293 6.44265 0.827501C6.46718 0.769966 6.42571 0.704102 6.36317 0.704102H5.00566C4.90423 0.704102 4.81201 0.759548 4.76767 0.850775C4.15807 2.10517 2.63762 5.74484 0.239644 11.691C0.213858 11.7549 0.200195 11.8242 0.200195 11.8932V16.2622Z" fill="white"/>
            <path d="M13.9445 0.860966C13.98 0.789072 13.9278 0.704102 13.8477 0.704102H12.5242C12.4227 0.704102 12.3344 0.751565 12.2901 0.84289C10.888 3.73166 4.84763 18.9922 3.4096 22.6346C3.33991 22.8111 3.47189 22.9976 3.66166 22.9976H4.79926C4.90883 22.9976 5.00711 22.9319 5.04825 22.8304C5.62856 21.3977 6.78109 18.5504 8.06926 15.3671C10.596 9.1239 13.7401 1.35397 13.9445 0.860966Z" fill="white"/>
            <path d="M18.3269 12.3008C18.3877 12.5578 18.7436 12.5825 18.8394 12.3365L19.6277 10.3103C19.6478 10.2588 19.6515 10.2023 19.6383 10.1486L17.3486 0.831229C17.347 0.824489 17.3467 0.826955 17.346 0.82005C17.3396 0.756664 17.28 0.704102 17.2163 0.704102H16.1512C16.0531 0.704102 15.9613 0.756867 15.9229 0.8471C15.8208 1.08738 15.7852 1.32502 15.7732 1.44326C15.7704 1.47074 15.7728 1.49833 15.7791 1.52521L18.3269 12.3008Z" fill="white"/>
            <path d="M17.3261 16.0278L14.6737 5.26928C14.6107 5.01378 14.2562 4.99136 14.1615 5.2369L13.4514 7.07872C13.4312 7.13121 13.4274 7.18662 13.4415 7.24107C14.1 9.77334 15.4636 15.0062 16.1648 17.6622C16.231 17.9129 16.5773 17.932 16.6724 17.6908C16.8883 17.1435 17.1259 16.5384 17.3261 16.0278Z" fill="white"/>
            <path d="M18.7034 22.2052L27.4942 0.704102H25.5478L17.6876 20.5308C17.5879 20.9322 17.1359 21.6362 16.0871 21.6107C15.0012 21.5809 14.6505 20.7295 14.6118 20.2572L12.2185 10.2439L11.051 13.0441L13.6299 22.482C13.6542 22.531 13.7849 22.7653 14.1512 22.9972H18.0003C18.4864 22.6511 18.6888 22.2368 18.7034 22.2052Z" fill="url(#paint0_diamond_316_388)"/>
            <path d="M22.4761 22.479L22.4802 22.4687L31.3007 1.07565C31.3737 0.89858 31.2435 0.704102 31.052 0.704102H29.6863C29.5766 0.704102 29.4779 0.77072 29.4368 0.872457L20.8569 22.1377C20.8539 22.1466 20.7893 22.3293 20.6689 22.5816C20.5793 22.7695 20.711 22.9976 20.9192 22.9976H21.9426C22.0005 22.9976 22.0575 22.9793 22.1022 22.9424C22.3848 22.7092 22.4719 22.4897 22.4761 22.479Z" fill="white"/>
            <path d="M26.5591 22.2388C26.9989 21.1782 29.3982 15.2107 31.5305 9.90801C31.5433 9.87611 31.5499 9.8422 31.5499 9.80781V5.44849C29.0621 11.6081 25.1953 21.1817 24.8608 22.0446C24.7876 22.2322 24.7092 22.4099 24.6266 22.5758C24.5325 22.765 24.6647 22.9998 24.876 22.9998H25.9183C25.9841 22.9998 26.0482 22.9763 26.095 22.9301C26.2853 22.7425 26.4381 22.5146 26.5591 22.2388Z" fill="white"/>
            <defs>
                <radialGradient id="paint0_diamond_316_388" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.4426 21.0293) rotate(-74.6669) scale(25.0903 100.29)">
                <stop stop-color="#2EC6E6"/>
                <stop offset="0.985613" stop-color="#67E6DE" stop-opacity="0.8"/>
                </radialGradient>
            </defs>
        </svg>
    </span>
  );
};

export { Logo };
